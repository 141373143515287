$(document).ready(function(){
	slickHomeTop();
	slickBottom();
	showHideLongText();
	toggleHamburger();
	closeMenuMobile();
	dataTables();
	changeDiv();
});

// Slick - home top carousel
function slickHomeTop() {
	$('.home-top-carousel').slick({
		dots: true,
		arrows: false
	});
}

// Slick - bottom carousel
function slickBottom() {
	$('.bottom-carousel').slick({
		dots: true,
		arrows: false
	});
}

// Show/hide long text on "Read More"
function showHideLongText() {
	new showHideText('.show-hide', { // class not used to avoid applying character limit
	    ellipseText : "...",
	    moreText    : "READ MORE",
	    lessText    : "READ LESS"
	});
}	

// Toggle hamburger menu
function toggleHamburger() {
	$('#hamburger').click( function() {
		if ($("#menu").hasClass("opened")) {
		    $("#menu.opened").removeClass("opened").fadeOut(250);
		    $(this).removeClass("hamburger--toggle");
		} else {
		    $("#menu").addClass("opened").fadeIn(250);
		    $(this).addClass("hamburger--toggle");
		}
	});
}

// Close menu on mobile when clicking outside of it
function closeMenuMobile() {

	$('html').on('click', '#hamburger', function(e){
	    e.stopPropagation();
	});

	$('html').click(function(){
		$('#hamburger').removeClass('hamburger--toggle');
	    $('#menu.opened').removeClass("opened").fadeOut(250);
	});	
}

// Data tables init
function dataTables() {
    $('.product-specs-table').DataTable({
    	paging: false,
    	ordering: false,
    	searching: false,
    	responsive: true,
    	info: false,
    	autoWidth: false
    });
}

// Inner side menu - change content on click
function changeDiv() {
	$(".side-menu-sub-button").click(function (event) {
		event.preventDefault();
		var id = $(this).attr('data-id');

		$('.side-menu-sub-button.active').removeClass('active');
		$(this).addClass('active');
		$('.side-menu-sub-content:visible').fadeOut(function(){
			$('#sub-content-' + id).fadeIn('fast');
		});
	});
}

